import {
  CSSProperties,
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import Keyboard, { KeyboardReactInterface } from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'

export interface IProps {
  handleKeyboardVisible: (value: boolean) => void
  inputName: string
  inputRect: DOMRect | null
  keyboardContainerRef: React.RefObject<HTMLDivElement>
  keyboardRef: MutableRefObject<KeyboardReactInterface | null>
  keyboardVisible: boolean
  onChange: (inputName: string, input: string) => void
  position: 'top' | 'bottom'
}

export const VirtualKeyboard: FunctionComponent<IProps> = ({
  handleKeyboardVisible,
  inputName,
  inputRect,
  keyboardContainerRef,
  keyboardRef,
  keyboardVisible,
  onChange,
  position,
}) => {
  const [layoutName, setLayoutName] = useState<'default' | 'shift'>('default')
  const localKeyboardRef = useRef<KeyboardReactInterface | null>(null)

  const onKeyPress = (button: string) => {
    if (button === '{shift}' || button === '{lock}') {
      setLayoutName(prev => (prev === 'default' ? 'shift' : 'default'))
    }
  }

  const handleChange = (input: string) => {
    onChange(inputName, input)
  }

  const keyboardStyle: CSSProperties = {
    position: 'fixed',
    left: inputRect ? `${inputRect.left - 20}px` : '0px',
    width: inputRect ? `${inputRect.width + 160}px` : '100%',
    zIndex: 1000,
    display: keyboardVisible ? 'block' : 'none',
  }

  if (inputRect) {
    const keyboardHeight = 250
    const screenHeight = window.innerHeight

    if (
      position === 'bottom' &&
      inputRect.bottom + keyboardHeight > screenHeight
    ) {
      keyboardStyle.top = `${inputRect.top - keyboardHeight}px`
    } else if (position === 'bottom') {
      keyboardStyle.top = `${inputRect.bottom + 10}px`
    } else {
      keyboardStyle.top = `${inputRect.top - keyboardHeight}px`
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const container = keyboardContainerRef.current
      if (container && !container.contains(event.target as Node)) {
        handleKeyboardVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      ref={keyboardContainerRef}
      style={keyboardStyle}
    >
      <Keyboard
        baseClass="keyboardText"
        keyboardRef={(r: KeyboardReactInterface | null) => {
          localKeyboardRef.current = r
          keyboardRef.current = r
        }}
        inputName={inputName}
        layoutName={layoutName}
        onChange={handleChange}
        onKeyPress={onKeyPress}
      />
    </div>
  )
}
