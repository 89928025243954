import {
  customProps,
  ITypographyProps,
  spacingProps,
} from 'components/_common/commonStyles'
import styled, { css, DefaultTheme } from 'styled-components'

export const commonTypographyStyles = (
  p: ITypographyProps & { theme: DefaultTheme }
) =>
  css`
    text-align: ${p.align ?? 'inherit'};
    margin: 0;
    padding: 0;
    ${p.onClick && 'cursor: pointer'};
    font-weight: ${(p.bold && 600) || (p.semiBold && 500) || 400};
    ${p.s && `font-size: ${p.s}px;`};
    ${p.lineHeight && `line-height: ${p.lineHeight};`};
    ${p.upperCase && `text-transform: uppercase;`};
    ${p.h && `height: ${p.h}`};
    color: ${() => {
      if (p.primary) return p.theme.colors.primary
      if (p.secondary) return p.theme.colors.textSecondary
      if (p.tertiary) return p.theme.colors.textTertiary
      return p.theme.colors.text
    }};
  `

export const H1 = styled.h1<ITypographyProps>(
  () => css`
    font-size: min(15vw, 96px);
    ${commonTypographyStyles};
    ${spacingProps};
    ${customProps}
  `
)

export const H2 = styled.h2<ITypographyProps>(
  () => css`
    font-size: min(7vw, 40px);
    ${commonTypographyStyles};
    ${spacingProps};
    ${customProps}
  `
)

export const H3 = styled.h3<ITypographyProps>(
  () => css`
    font-size: 32px;
    ${commonTypographyStyles};
    ${spacingProps};
    ${customProps}
  `
)

export const H4 = styled.h4<ITypographyProps>(
  () => css`
    font-size: 20px;
    ${commonTypographyStyles};
    ${spacingProps};
    ${customProps}
  `
)

// body means only size, bold and color via props
export const Body1 = styled.p<ITypographyProps>(
  ({ theme: { isKiosk } }) => css`
    font-size: ${isKiosk ? '14px' : 'min(6vw, 17px)'};
    ${commonTypographyStyles};
    ${spacingProps};
    ${customProps}
  `
)

export const Body2 = styled.p<ITypographyProps>(
  () => css`
    font-size: min(5vw, 15px);
    ${commonTypographyStyles};
    ${spacingProps};
    ${customProps}
  `
)

export const Body4 = styled.p<ITypographyProps>(
  ({ theme: { isKiosk } }) => css`
    font-size: ${isKiosk ? '11px' : 'min(6vw, 15px)'};
    ${commonTypographyStyles};
    ${spacingProps};
    ${customProps}
  `
)

export const Span = styled.span<ITypographyProps>(
  () => css`
    ${commonTypographyStyles};
    ${spacingProps};
    ${customProps}
  `
)

export const ErrorMessage = styled.span(
  ({ theme: { colors } }) => css`
    color: ${colors.error};
    font-size: 16px;
    text-align: left;
  `
)

export const MandatoryStar = styled.span(
  ({ theme: { colors } }) => css`
    color: ${colors.error};
  `
)

export const DiscountText = styled.p<ITypographyProps>(
  ({ theme: { colors } }) => css`
    ${commonTypographyStyles};
    ${spacingProps};
    font-size: 16px;
    color: ${colors.error};
    font-weight: 400;
    text-decoration-line: line-through;
  `
)
