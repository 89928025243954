import * as Common from 'components/_common/Common'
import {
  customProps,
  ICustomStyledProps,
} from 'components/_common/commonStyles'
import * as Typography from 'components/_common/Typography'
import { MandatoryStar } from 'components/_common/Typography'
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form'
import styled, { css } from 'styled-components'

interface IInputTextProps<T extends FieldValues> {
  disabled?: boolean
  form: UseFormReturn<T>
  keyboardContainerRef?: React.RefObject<HTMLDivElement>
  label?: string
  mandatory?: boolean
  name: Path<T>
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  placeholder?: string
  secondary?: boolean
  type?: 'text' | 'number'
  value?: string
}

export const StyledTextField = styled.input<{
  isError: boolean
  secondary?: boolean
}>(
  ({ theme: { colors, params }, isError, secondary }) => css`
    ${customProps};
    height: 50px;
    padding: 0 28px;
    border: 1px solid ${isError ? colors.error : colors.textSecondary};
    color: ${colors.textSecondary};
    width: 100%;
    border-radius: ${params.buttonBorderRadius};

    ${secondary &&
    css`
      background: none;
    `}
  `
)

const InputText = <T extends FieldValues>({
  disabled = false,
  form,
  keyboardContainerRef,
  label = '',
  mandatory = false,
  name,
  onFocus,
  placeholder,
  secondary = false,
  type = 'text',
  value,
  ...rest
}: IInputTextProps<T> & ICustomStyledProps) => {
  const { control } = form

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => {
        const error = errors[name] as { message: string }

        return (
          <Common.Div
            ref={keyboardContainerRef}
            flex="column"
            w="100%"
            {...rest}
          >
            {label && (
              <Common.Label secondary>
                {label}
                <MandatoryStar>{mandatory ? '*' : ''}</MandatoryStar>
              </Common.Label>
            )}
            <StyledTextField
              {...field}
              autoComplete="new-password"
              disabled={disabled}
              isError={!!error}
              onFocus={onFocus}
              placeholder={placeholder}
              secondary={secondary}
              type={type}
              value={value ?? field.value ?? ''}
            />
            {error && (
              <Typography.ErrorMessage>
                {error?.message ?? ''}
              </Typography.ErrorMessage>
            )}
          </Common.Div>
        )
      }}
    />
  )
}

export default InputText
