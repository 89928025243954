import IconArrowLeft from 'assets/icons/iconArrowLeft.svg'
import IconArrowLeftNoBrand from 'assets/icons/iconArrowLeftNoBrand.svg'
import * as Common from 'components/_common/Common'
import { TButtonClickHandler } from 'components/_common/commonStyles'
import * as Typography from 'components/_common/Typography'
import { useTranslation } from 'react-i18next'

const BackButton = ({ onClick }: { onClick: TButtonClickHandler }) => {
  const { t } = useTranslation()
  const variantTheme = process.env.REACT_APP_VARIANT

  return (
    <Common.Div
      flex="row"
      gap="8px"
      alignItems="center"
      onClick={onClick}
    >
      {variantTheme === 'NO_BRAND' ? (
        <Common.SvgIcon src={IconArrowLeftNoBrand} />
      ) : (
        <Common.SvgIcon src={IconArrowLeft} />
      )}
      <Typography.Body2 secondary>{t('navigation.back')}</Typography.Body2>
    </Common.Div>
  )
}

export default BackButton
