import * as Common from 'components/_common/Common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const StyledSelect = styled.select(
  ({ theme: { colors, spacing } }) => css`
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: ${colors.bar};
    border: none;
    color: ${colors.secondary};
    padding: ${spacing(1)};
    width: 100%;

    :focus-visible {
      outline: none;
    }
  `
)

const LanguagePicker = () => {
  const { i18n } = useTranslation()
  const lang = i18n.language

  const languages = [
    { code: 'en', label: '🇬🇧 EN' },
    { code: 'pl', label: '🇵🇱 PL' },
    { code: 'es', label: '🇪🇸 ES' },
  ]

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    void i18n.changeLanguage(event.target.value)
  }

  return (
    <Common.Div
      flex="row"
      alignItems="center"
    >
      <StyledSelect
        value={lang}
        onChange={handleLanguageChange}
      >
        {languages.map(({ code, label }) => (
          <option
            key={code}
            value={code}
          >
            {label}
          </option>
        ))}
      </StyledSelect>
    </Common.Div>
  )
}

export default LanguagePicker
