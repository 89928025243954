import { yupResolver } from '@hookform/resolvers/yup'
import * as Common from 'components/_common/Common'
import { KioskViewContainer, transparentCss } from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import {
  notify,
  ToastTypes,
} from 'components/_common/ToastNotification/ToastNotification'
import * as Typography from 'components/_common/TypographyKiosk'
import InputText from 'components/_inputs/InputText'
import {
  ROUTE_CHECKIN_CONFIRM_EMAIL,
  ROUTE_CHECKIN_RESERVATION_DETAILS,
  ROUTE_CHECKOUT_CONFIRM_EMAIL,
  ROUTE_CHECKOUT_RESERVATION_DETAILS,
} from 'constants/routes'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import { selectIsKiosk } from 'redux/slices/app'
import {
  selectReservationDetails,
  selectReservationError,
  selectVerifyMessage,
  selectVerifyStatus,
} from 'redux/slices/reservation/reservationSelectors'
import { verifyReservation } from 'redux/slices/reservation/reservationSlice'
import { selectUserDetails } from 'redux/slices/user/userSelector'
import { fetchUser } from 'redux/slices/user/userSlice'
import styled, { css, useTheme } from 'styled-components'
import { confirmReservationSchema } from 'utils/validation/search'

export const FormContainer = styled(Common.Div)<{
  isInputOpened?: boolean
}>(
  ({ theme: { params } }) => css`
    padding: 38px 105px;
    height: 372px;
    width: 920px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: ${params.buttonBorderRadius};
    ${transparentCss};
  `
)

const ViewCheckInOutConfirmEmailKiosk = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const reservation = useSelector(selectReservationDetails)
  const verifyReservationStatus = useSelector(selectVerifyStatus)
  const verifyMessage = useSelector(selectVerifyMessage)
  const reservationError = useSelector(selectReservationError)
  const userDetails = useSelector(selectUserDetails)
  const isKiosk = useSelector(selectIsKiosk)

  const confirmationForm = useForm({
    defaultValues: {
      confirmationNumber: '',
    },
    resolver: yupResolver(confirmReservationSchema(t)),
  })

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [keyboardVisible, setKeyboardVisible] = useState(false)
  const [confirmationNumber, setConfirmationNumber] = useState(
    confirmationForm.watch('confirmationNumber') || ''
  )

  useEffect(() => {
    if (!keyboardVisible) {
      setConfirmationNumber(
        confirmationForm.getValues('confirmationNumber') || ''
      )
    }
  }, [keyboardVisible, confirmationForm])

  const handleSearch = (data: { confirmationNumber: string }) => {
    if (reservation && reservation.users_uuid) {
      dispatch(
        verifyReservation({
          uuid: reservation.reservation_code,
          pin: data.confirmationNumber,
        })
      )
    }
  }

  const updateInputValue = (input: string) => {
    setConfirmationNumber(input)
    confirmationForm.setValue('confirmationNumber', input)
  }

  const handleKeyPress = (key: string) => {
    const newValue =
      key === '{bksp}'
        ? confirmationNumber.slice(0, -1)
        : confirmationNumber + key
    setConfirmationNumber(newValue)
    confirmationForm.setValue('confirmationNumber', newValue)
  }

  useEffect(() => {
    if (reservation) {
      dispatch(fetchUser(reservation.users_uuid))
    }
  }, [dispatch, reservation?.users_uuid])

  useEffect(() => {
    if (verifyReservationStatus === 'succeeded') {
      notify(verifyMessage)[ToastTypes.SUCCESS]()
      if (pathname === ROUTE_CHECKIN_CONFIRM_EMAIL) {
        navigate(ROUTE_CHECKIN_RESERVATION_DETAILS)
      } else if (pathname === ROUTE_CHECKOUT_CONFIRM_EMAIL) {
        navigate(ROUTE_CHECKOUT_RESERVATION_DETAILS)
      }
    } else if (verifyReservationStatus === 'failed') {
      notify(reservationError)[ToastTypes.ERROR]()
    }
  }, [dispatch, verifyReservationStatus])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setKeyboardVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <KioskViewContainer bgUrl={theme.assets.searchBackground}>
      <Common.Div
        flex="row"
        justifyContent="center"
        w="100%"
        mt={12}
      >
        <FormContainer>
          <Common.Div
            flex="row"
            justifyContent="space-between"
          >
            <div>
              <Typography.Body
                s={16}
                secondary
                mb={2}
              >
                {t('confirmReservation.hello')}
              </Typography.Body>
              <Typography.Body
                s={40}
                mb={4}
                secondary
              >
                {`${userDetails?.first_name || ''} ${
                  userDetails?.last_name || ''
                }`}
              </Typography.Body>
            </div>
            <Common.Div
              flex="column"
              gap="8px"
            >
              <Typography.Body
                s={16}
                secondary
              >
                {t('confirmReservation.yourReservationNumber')}
              </Typography.Body>
              <Typography.Body
                s={24}
                secondary
              >
                {reservation?.reservation_code || ''}
              </Typography.Body>
            </Common.Div>
          </Common.Div>
          <Common.Div flex="column">
            <Typography.Body
              s={16}
              mb={5.5}
              secondary
            >
              <Trans i18nKey="confirmReservation.almostDone" />
            </Typography.Body>
            <form onSubmit={confirmationForm.handleSubmit(handleSearch)}>
              <Common.Div
                position="relative"
                flex="row"
                gap="16px"
                ref={wrapperRef}
              >
                <InputText
                  form={confirmationForm}
                  name="confirmationNumber"
                  onClick={() => setKeyboardVisible(true)}
                  onFocus={() => setKeyboardVisible(true)}
                  secondary
                  type="text"
                  w="529px"
                  placeholder={t('findReservation.pinPlaceholder')}
                />
                {keyboardVisible && isKiosk && (
                  <Common.Div
                    position="absolute"
                    top={65}
                    w="360px"
                  >
                    <Keyboard
                      baseClass="keyboardPinConfirm"
                      onChange={(input: string) => updateInputValue(input)}
                      onKeyPress={handleKeyPress}
                      inputName="resNumber"
                      layout={{
                        default: ['1 2 3', '4 5 6', '7 8 9', '* 0 #', '{bksp}'],
                      }}
                      display={{ '{bksp}': '⌫' }}
                    />
                  </Common.Div>
                )}
                <NextButton
                  disabled={verifyReservationStatus === 'loading'}
                  type="submit"
                />
              </Common.Div>
            </form>
          </Common.Div>
        </FormContainer>
      </Common.Div>
    </KioskViewContainer>
  )
}

export default ViewCheckInOutConfirmEmailKiosk
