import IconCalendar from 'assets/icons/IconCalendarSecondary.svg'
import IconIndicatorDown from 'assets/icons/IconIndicatorDown.svg'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import { TFormSearchRoomChosenInput } from 'components/_forms/FormSearchRoom'
import { format } from 'date-fns'
import { useOnClickOutside } from 'hooks/useClickOutside'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { DateRange, Range } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  selectFitCustomResolution,
  selectIsKiosk,
  setKioskInputOpened,
} from 'redux/slices/app'
import { selectRoomSearchParams } from 'redux/slices/room'
import styled, { css } from 'styled-components'
import { IFindRoomForm } from 'types/room'
import { getDateFnsLocale } from 'utils/dates'

interface IInputDateRangeProps {
  form: UseFormReturn<IFindRoomForm>
  selectedInput: TFormSearchRoomChosenInput
  setSelectedInput: Dispatch<SetStateAction<TFormSearchRoomChosenInput>>
}

const StyledDateRange = styled(DateRange)<{
  shouldSqueeze?: boolean
}>(
  ({ shouldSqueeze, theme: { colors, params } }) => css`
    scale: ${shouldSqueeze ? '0.9' : '1'};
    background: #e2e3e4;
    border-radius: ${params.buttonBorderRadius};
    width: 100%;

    .rdrNextPrevButton {
      background: url(${IconIndicatorDown}) no-repeat;
      width: 17px;
      height: 10px;

      i {
        border: none;
      }
    }
    .rdrMonthName {
      color: ${colors.primary};
      font-weight: 500;
      text-transform: capitalize;
    }
    .rdrMonthAndYearPickers {
      text-transform: capitalize;

      select {
        color: ${colors.primary};
      }
    }
    .rdrPprevButton {
      transform: rotate(90deg);
    }
    .rdrNextButton {
      transform: rotate(-90deg);
    }
    .rdrInRange {
      color: ${colors.primary} !important;
    }
    .rdrDayNumber {
      span {
        color: #545454 !important;
        font-weight: 500;
      }
    }
    .rdrMonth {
      width: 100%;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
      color: rgba(255, 255, 255, 1) !important;
    }
  `
)

const StyledDropdownModal = styled(Common.DropdownModal)<{
  shouldSqueeze?: boolean
}>(
  ({ shouldSqueeze, theme: { spacing, isKiosk, colors, params } }) => css`
    border-radius: ${params.buttonBorderRadius};
    padding: ${shouldSqueeze || isKiosk ? '0' : `${spacing(2)}`};
    width: 100%;

    .rdrWeekDay {
      color: ${colors.primary};
    }
  `
)

const InputDateRange = ({
  form,
  selectedInput,
  setSelectedInput,
}: IInputDateRangeProps) => {
  const ref = useRef(null)
  const isKiosk = useAppSelector(selectIsKiosk)
  const dispatch = useAppDispatch()
  const { i18n, t } = useTranslation()
  const language: string = i18n.language
  const dateLocale = getDateFnsLocale(language)
  const searchData = useAppSelector(selectRoomSearchParams)

  useOnClickOutside(ref, () => {
    setSelectedInput(null)
    if (isKiosk) {
      dispatch(setKioskInputOpened(false))
    }
  })

  const shouldSqueezeDatepicker = useAppSelector(state =>
    selectFitCustomResolution(state, 440)
  )

  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 1266)
  )
  const shouldVerticalCalendar = isKiosk
    ? false
    : useAppSelector(state => selectFitCustomResolution(state, 1174))

  const { control, formState } = form
  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: undefined,
      key: 'selection',
    },
  ])

  const datePeriodsText = () => {
    if (state[0].startDate && state[0].endDate) {
      return `${format(state[0].startDate, 'EEE d MMM', {
        locale: dateLocale,
      })} - ${format(state[0].endDate, 'EEE d MMM', {
        locale: dateLocale,
      })}`
    }

    return `${t('reservationDetails.checkIn')} - ${t(
      'reservationDetails.checkOut'
    )}`
  }
  const isOpen = selectedInput === 'date'

  const handleIconClick = () => {
    if (isOpen) {
      setSelectedInput(null)
      if (isKiosk) {
        dispatch(setKioskInputOpened(false))
      }
    } else {
      setSelectedInput('date')
      if (isKiosk) {
        dispatch(setKioskInputOpened(true))
      }
    }
  }

  const error = formState?.errors.stayDate?.startDate as { message: string }

  useEffect(() => {
    if (searchData.stayDate.startDate && searchData.stayDate.endDate) {
      setState([
        {
          startDate: new Date(searchData.stayDate.startDate),
          endDate: new Date(searchData.stayDate.endDate),
          key: 'selection',
        },
      ])
    }
  }, [searchData])

  return (
    <Common.Div
      mb={isMobile ? 2 : 0}
      flex="column"
      gap="8px"
    >
      <Common.DropdownWithIcon
        onClick={handleIconClick}
        isMobile={isMobile}
      >
        <Common.SvgIcon
          src={IconCalendar}
          w="16px"
          h="18px"
          mr={1}
        />
        <Typography.Body2
          semiBold
          secondary
          autoCapitalize="on"
        >
          {datePeriodsText()}
        </Typography.Body2>
        <Common.SvgIcon
          src={IconIndicatorDown}
          w="17px"
          h="10px"
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </Common.DropdownWithIcon>

      {isOpen && (
        <StyledDropdownModal
          ref={ref}
          shouldSqueeze={shouldSqueezeDatepicker}
        >
          <Controller
            name="stayDate"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Common.Div>
                  <StyledDateRange
                    locale={getDateFnsLocale(i18n.language)}
                    shouldSqueeze={shouldSqueezeDatepicker}
                    showDateDisplay={false}
                    showMonthAndYearPickers={false}
                    weekStartsOn={0}
                    onChange={item => {
                      const { startDate, endDate } = item.selection

                      if (startDate) {
                        if (!endDate || endDate <= startDate) {
                          const newEndDate = new Date(startDate)
                          newEndDate.setDate(startDate.getDate() + 1)
                          setState([{ ...item.selection, endDate: newEndDate }])
                          onChange({ ...item.selection, endDate: newEndDate })
                        } else {
                          setState([item.selection])
                          onChange(item.selection)
                        }
                      }
                    }}
                    ranges={state}
                    months={2}
                    direction={
                      shouldVerticalCalendar ? 'vertical' : 'horizontal'
                    }
                    minDate={new Date()}
                  />
                </Common.Div>
              )
            }}
          />
        </StyledDropdownModal>
      )}
      {error && (
        <Typography.ErrorMessage>
          {error?.message ?? ''}
        </Typography.ErrorMessage>
      )}
    </Common.Div>
  )
}

export default InputDateRange
