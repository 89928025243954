import * as yup from 'yup'
import { TFunction } from 'i18next'

export const errorMessagesSchema = (t: TFunction) => {
  const phoneRegExp = /^\+?[1-9]\d{1,14}$/

  const phoneValidation = yup
    .string()
    .matches(phoneRegExp, t('validation.invalidPhoneFormat'))
    .required(t('validation.phoneRequired'))

  const emailValidation = yup
    .string()
    .email(t('validation.invalidEmailFormat'))
    .required(t('validation.required'))

  const reservationSchema = yup.object().shape({
    firstName: yup.string().required(t('validation.firstNameRequired')),
    lastName: yup.string().required(t('validation.lastNameRequired')),
    email: emailValidation,
    phone: phoneValidation,
    country: yup.string().required(t('validation.countryRequired')),
  })

  const reservationSchemaKiosk = yup.object().shape({
    firstName: yup.string().required(t('validation.firstNameRequired')),
    lastName: yup.string().required(t('validation.lastNameRequired')),
    email: emailValidation,
    phone: phoneValidation,
    country: yup.string().required(t('validation.countryRequired')),
    terms: yup
      .boolean()
      .oneOf([true], t('validation.termsRequired'))
      .required(),
  })

  return { reservationSchema, reservationSchemaKiosk }
}
