import * as Common from 'components/_common/Common'
import {
  customProps,
  ICustomStyledProps,
} from 'components/_common/commonStyles'
import * as Typography from 'components/_common/Typography'
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form'
import styled, { css } from 'styled-components'

interface IInputTextProps<T extends FieldValues> {
  name: Path<T>
  label?: string
  secondaryLabel?: string
  disabled?: boolean
  form: UseFormReturn<T>
  type?: 'text' | 'number'
  mandatory?: boolean
}

export const StyledCheckbox = styled(Common.Input)<{ isError?: boolean }>(
  ({ theme: { colors }, isError }) => css`
    ${customProps};
    appearance: none;
    height: 20px;
    width: 20px;
    border: 1px solid ${isError ? colors.error : colors.textSecondary};
    cursor: pointer;
    position: relative;

    &:checked {
      background-color: ${colors.primary};
      border-color: ${colors.primary};
    }

    &:checked::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  `
)

const InputCheckbox = <T extends FieldValues>({
  name,
  form,
  label = '',
  secondaryLabel = '',
  disabled = false,
  mandatory = false,
  ...rest
}: IInputTextProps<T> & ICustomStyledProps) => {
  const { control } = form

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, formState: { errors } }) => {
        const error = errors[name] as { message: string }

        return (
          <Common.Div
            flex="column"
            {...rest}
          >
            <label
              style={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
              }}
              htmlFor={name}
            >
              <StyledCheckbox
                id={name}
                name={name}
                type="checkbox"
                disabled={disabled}
                onChange={onChange}
                mr={2}
              />
              {label && (
                <Typography.Body1
                  secondary
                  semiBold
                  mr={0.5}
                >
                  {label}
                  <Typography.MandatoryStar>
                    {mandatory ? '*' : ''}
                  </Typography.MandatoryStar>
                </Typography.Body1>
              )}
              {secondaryLabel && (
                <Typography.Body1 secondary>{secondaryLabel}</Typography.Body1>
              )}
            </label>
            {error && (
              <Typography.ErrorMessage>
                {error?.message ?? ''}
              </Typography.ErrorMessage>
            )}
          </Common.Div>
        )
      }}
    />
  )
}

export default InputCheckbox
