import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import { MandatoryStar } from 'components/_common/Typography'
import { useEffect, useRef, useState, useCallback } from 'react'
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form'
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import styled, { css, useTheme } from 'styled-components'
import { useAppSelector } from 'hooks/useRedux'
import { selectIsKiosk } from 'redux/slices/app'

interface IInputTextProps<T extends FieldValues> {
  name: Path<T>
  label?: string
  disabled?: boolean
  form: UseFormReturn<T>
  type?: 'text' | 'number'
  mandatory?: boolean
  maxLength?: number
  defaultCountryCode?: string
}

const StyledPhoneInput = styled(PhoneInput)<PhoneInputProps>(() => css``)

const InputPhone = <T extends FieldValues>({
  name,
  form,
  label = '',
  disabled = false,
  mandatory = false,
  maxLength = 12,
  defaultCountryCode = '48',
  ...rest
}: IInputTextProps<T>) => {
  const { control, setValue } = form
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const isKiosk = useAppSelector(selectIsKiosk)

  const [inputValue, setInputValue] = useState<string>(`+${defaultCountryCode}`)
  const [keyboardVisible, setKeyboardVisible] = useState(false)
  const [currentCountryCode, setCurrentCountryCode] =
    useState<string>(defaultCountryCode)

  const updateInputValue = useCallback(
    (value: string, countryData?: CountryData) => {
      const dialCode = countryData
        ? `+${countryData.dialCode}`
        : `+${currentCountryCode}`
      let newValue = value

      if (!value.startsWith(dialCode)) {
        newValue = `${dialCode}${value.replace(/^\+\d+/, '')}`
      }

      if (newValue.length <= maxLength) {
        setInputValue(newValue)
      }
    },
    [maxLength, currentCountryCode]
  )

  const handleCountryChange = (val: string, countryData: CountryData) => {
    setCurrentCountryCode(countryData.dialCode)
    updateInputValue(val, countryData)
  }

  const handleKeyPress = (button: string) => {
    setInputValue(prev => {
      const dialCode = `+${currentCountryCode}`
      if (button === '{bksp}') {
        const updatedValue =
          prev.length > dialCode.length ? prev.slice(0, -1) : dialCode
        return updatedValue
      }
      return prev.length < maxLength ? prev + button : prev
    })
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute('inputMode', 'text')
      inputRef.current.setAttribute('pattern', '[0-9A-Za-z]*')
    }
  }, [])

  useEffect(() => {
    setValue(name, inputValue as PathValue<T, Path<T>>)
  }, [inputValue, setValue, name])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setKeyboardVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, formState: { errors } }) => {
        const error = errors[name] as { message?: string } | undefined

        return (
          <Common.Div
            ref={wrapperRef}
            flex="column"
            w="100%"
            {...rest}
          >
            {label && (
              <Common.Label secondary>
                {label} {mandatory && <MandatoryStar>*</MandatoryStar>}
              </Common.Label>
            )}
            <Common.Div
              position="relative"
              w="100%"
            >
              {keyboardVisible && isKiosk && (
                <Common.Div
                  position="absolute"
                  bottom={75}
                  w="100%"
                >
                  <Keyboard
                    baseClass="keyboardInputPhone"
                    onChange={(input: string) => updateInputValue(input)}
                    onKeyPress={handleKeyPress}
                    inputName="phoneInput"
                    layout={{
                      default: ['1 2 3', '4 5 6', '7 8 9', '* 0 #', '{bksp}'],
                    }}
                    display={{ '{bksp}': '⌫' }}
                  />
                </Common.Div>
              )}
              <StyledPhoneInput
                autocompleteSearch={false}
                value={inputValue || value}
                disabled={disabled}
                onChange={handleCountryChange}
                onFocus={() => setKeyboardVisible(true)}
                country={currentCountryCode}
                inputProps={{
                  ref: (el: HTMLInputElement) => {
                    inputRef.current = el
                  },
                  inputMode: 'text',
                  pattern: '[0-9A-Za-z]*',
                  maxLength,
                }}
                containerStyle={{
                  borderRadius: theme.params.buttonBorderRadius,
                  height: '50px',
                }}
                inputStyle={{
                  borderColor: '#00000080',
                  borderRadius: theme.params.buttonBorderRadius,
                  height: '50px',
                  width: '100%',
                }}
              />
            </Common.Div>
            {error?.message && (
              <Typography.ErrorMessage>{error.message}</Typography.ErrorMessage>
            )}
          </Common.Div>
        )
      }}
    />
  )
}

export default InputPhone
