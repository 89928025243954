import * as Common from 'components/_common/Common'
import { VirtualKeyboard } from 'components/_common/VirtualKeyboard'
import InputCheckbox from 'components/_inputs/InputCheckbox'
import InputPhone from 'components/_inputs/InputPhone'
import InputSelect from 'components/_inputs/InputSelect'
import InputText from 'components/_inputs/InputText'
import { countryOptions } from 'constants/countries'
import { useRef, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { KeyboardReactInterface } from 'react-simple-keyboard'
import { IYourInfoFormKiosk } from 'types/room'
import { BorderFormBox } from 'views/desktopMobile/ViewYourReservation.styled'

const FormYourInfoKiosk = ({
  form,
}: {
  form: UseFormReturn<IYourInfoFormKiosk>
}) => {
  const { t } = useTranslation()
  const isError = Object.keys(form.formState.errors).length !== 0
  const variantTheme = process.env.REACT_APP_VARIANT
  const [activeInput, setActiveInput] = useState<string>('')
  const [inputRect, setInputRect] = useState<DOMRect | null>(null)
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({})
  const [keyboardVisible, setKeyboardVisible] = useState<boolean>(false)
  const keyboardContainerRef = useRef<HTMLDivElement>(null)
  const keyboardRef = useRef<KeyboardReactInterface | null>(null)

  const handleInputChange = (inputName: string, value: string) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [inputName]: value,
    }))
    form.setValue(inputName as keyof IYourInfoFormKiosk, value)
  }

  const handleInputFocus = (
    inputName: string,
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    setKeyboardVisible(true)
    setActiveInput(inputName)
    const rect = event.target.getBoundingClientRect()
    setInputRect(rect)
  }

  return (
    <BorderFormBox
      h="360px"
      w="754px"
    >
      <Common.Div
        mb={2}
        flex="column"
      >
        <Common.Div
          flex="row"
          gap="16px"
          mb={isError ? 0.5 : 3}
        >
          <InputText
            label={t('reservationDetails.form.firstName')}
            name="firstName"
            form={form}
            mandatory
            onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
              handleInputFocus('firstName', e)
            }
            onClick={() => setKeyboardVisible(true)}
            value={inputValues.firstName || ''}
          />
          <InputText
            label={t('reservationDetails.form.lastName')}
            name="lastName"
            form={form}
            mandatory
            onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
              handleInputFocus('lastName', e)
            }
            onClick={() => setKeyboardVisible(true)}
            value={inputValues.lastName || ''}
          />
        </Common.Div>
        <Common.Div
          flex="row"
          gap="16px"
          mb={isError ? 0.5 : 3}
        >
          <InputSelect
            label={t('reservationDetails.form.country')}
            name="country"
            form={form}
            options={countryOptions}
            mandatory
          />
        </Common.Div>
        <Common.Div
          flex="row"
          gap="16px"
          mb={isError ? 0.5 : 2}
        >
          <Common.Div w="100%">
            <InputPhone
              label={t('reservationDetails.form.telephone')}
              name="phone"
              form={form}
              mandatory
            />
          </Common.Div>
          <InputText
            label={t('reservationDetails.form.email')}
            name="email"
            form={form}
            isMobile={false}
            mandatory
            onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
              handleInputFocus('email', e)
            }
            onClick={() => setKeyboardVisible(true)}
            value={inputValues.email || ''}
          />
        </Common.Div>
        <Common.Div
          flex="row"
          gap="16px"
          mb={isError ? 0.5 : 2}
        >
          <InputCheckbox
            name="terms"
            form={form}
            label={
              variantTheme === 'NO_BRAND'
                ? t('reservationDetails.form.termsNoBrand')
                : t('reservationDetails.form.terms')
            }
            mandatory
          />
        </Common.Div>
      </Common.Div>

      <VirtualKeyboard
        handleKeyboardVisible={setKeyboardVisible}
        inputName={activeInput}
        inputRect={inputRect}
        keyboardContainerRef={keyboardContainerRef}
        keyboardRef={keyboardRef}
        keyboardVisible={keyboardVisible}
        onChange={handleInputChange}
        position="bottom"
      />
    </BorderFormBox>
  )
}

export default FormYourInfoKiosk
