import paymentCompletedBg from 'assets/bgs/paymentCompletedBg.png'
import creditCardCheck from 'assets/icons/iconCreditCardCheck.svg'
import qrCodeIcon from 'assets/icons/iconQr.svg'
import * as Common from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import * as Typography from 'components/_common/Typography'
import WideModal from 'components/_modals/WideModal'
import {
  ROUTE_MAKE_RESERVATION_CARD_PRINTING,
  ROUTE_MAKE_RESERVATION_THANK_YOU,
} from 'constants/routes'
import { isWithinInterval, parseISO } from 'date-fns'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BookingResponse } from 'redux/slices/booking/bookingSlice'
import { selectUserDetails } from 'redux/slices/user/userSelector'
import { fetchUser } from 'redux/slices/user/userSlice'
import { ICommonModalProps } from 'types/common'

interface ModalPaymentCompletedProps extends ICommonModalProps {
  bookingDetails: BookingResponse['data'] | null
}

interface ModalActionsProps {
  email: string | null
  bookingDetails: BookingResponse['data'] | null
}

const ModalActions = ({ email, bookingDetails }: ModalActionsProps) => {
  const navigate = useNavigate()

  const isReservationToday =
    bookingDetails?.date_from && bookingDetails?.date_to
      ? isWithinInterval(new Date(), {
          start: parseISO(bookingDetails.date_from),
          end: parseISO(bookingDetails.date_to),
        })
      : false

  const handleNextButtonClick = () => {
    if (isReservationToday) {
      navigate(ROUTE_MAKE_RESERVATION_CARD_PRINTING)
    } else {
      navigate(ROUTE_MAKE_RESERVATION_THANK_YOU)
    }
  }

  return (
    <Common.Div
      flex="row"
      justifyContent="space-between"
      alignItems="center"
      h="130px"
    >
      <Common.Div
        flex="row"
        gap="32px"
      >
        <Common.SvgIcon
          src={qrCodeIcon}
          ml={4}
        />
        <Typography.Body4
          s={14}
          secondary
          lineHeight={1.5}
        >
          <Trans i18nKey="payment.modal.actionText" />
          <Typography.Span
            s={14}
            semiBold
            primary
          >
            {email}{' '}
          </Typography.Span>
          <Trans i18nKey="payment.modal.actionTextCd" />
        </Typography.Body4>
      </Common.Div>
      <NextButton onClick={handleNextButtonClick} />
    </Common.Div>
  )
}

const ModalPaymentCompleted = ({
  isOpen,
  setIsOpen,
  bookingDetails,
}: ModalPaymentCompletedProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userDetails = useSelector(selectUserDetails)

  useEffect(() => {
    if (bookingDetails?.users_uuid) {
      dispatch(fetchUser(bookingDetails.users_uuid))
    }
  }, [bookingDetails?.users_uuid, dispatch])

  if (!bookingDetails) {
    return null
  }

  return (
    <WideModal
      variant="action"
      bgSrc={paymentCompletedBg}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      bottomChildren={
        <ModalActions
          email={userDetails?.email ?? null}
          bookingDetails={bookingDetails}
        />
      }
      closeOnClickOutside={false}
    >
      <Common.Div
        flex="column"
        fullyCentered
      >
        <Common.SvgIcon
          src={creditCardCheck}
          mb={2}
        />
        <Typography.H2
          tertiary
          mb={2}
        >
          {t('payment.modal.header')}
        </Typography.H2>
        <Typography.Body2 tertiary>
          {t('payment.modal.desc')}
          {bookingDetails.reservation_code}
        </Typography.Body2>
      </Common.Div>
    </WideModal>
  )
}

export default ModalPaymentCompleted
