import styled, { css } from 'styled-components'
import {
  customProps,
  ICustomStyledProps,
} from 'components/_common/commonStyles'
import * as Common from 'components/_common/Common'

export const ViewYourReservationContainer = styled(Common.Div)(
  ({ theme: { spacing }, isMobile }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: ${isMobile ? spacing(2) : spacing(8)};
  `
)

export const RoomDetailsContainer = styled(Common.Div)(
  ({ theme: { isKiosk } }) => css`
    width: ${isKiosk ? '754px' : 'auto'};
  `
)

export const BorderFormBox = styled(Common.Div)(
  ({ theme: { colors, params } }) => css`
    border: 1px solid ${colors.border};
    padding: 24px;
    border-radius: ${params.buttonBorderRadius};
  `
)

export const RoomThumbnail = styled.img<ICustomStyledProps>(
  ({ theme: { spacing, params } }) => css`
    ${customProps};
    border-radius: ${params.imageBorderRadius};
    height: 73px;
    margin-right: ${spacing(2)};
    width: 120px;
  `
)

export const VerticalBorder = styled.div<ICustomStyledProps>(
  ({ theme: { colors } }) => css`
    ${customProps};
    border-right: 0.5px solid ${colors.border};
    height: 50px;
  `
)

export const FvIcon = styled(Common.SvgIcon)<ICustomStyledProps>(
  ({ theme: { spacing } }) => css`
    ${customProps};
    font-size: 40px;
    width: ${spacing(4)};
  `
)
