import * as Common from 'components/_common/Common'
import { customProps } from 'components/_common/commonStyles'
import styled, { css } from 'styled-components'

export const RoomThumbnail = styled(Common.SvgIcon)(
  ({ isMobile, theme: { isKiosk, params } }) => css`
    ${customProps};
    ${isMobile ? 'auto' : '169px'};
    background-size: contain;
    border-radius: ${params.imageBorderRadius};
    height: 169px;
    width: ${isMobile ? '100%' : '236px'};

    ${!isKiosk &&
    css`
      @media (max-width: 1200px) {
        height: auto;
      }
    `}

    ${isKiosk &&
    css`
      width: 190px;
      height: 125px;
    `}
  `
)

export const FullBorderSeparator = styled(Common.Div)(
  ({ theme: { colors } }) => css`
    border-top: 1px solid ${colors.border};
    width: 100%;
    margin-top: 8px;
  `
)

export const GalleryButton = styled(Common.SvgIcon)(
  ({ theme: { spacing }, isMobile }) => css`
    ${customProps};
    width: ${isMobile ? '24px' : '18px'};
    height: ${isMobile ? '24px' : '18px'};
    position: absolute;
    top: ${isMobile ? spacing(2) : spacing(1)};
    right: ${isMobile ? spacing(2) : spacing(5)};
  `
)

export const PriceContainer = styled(Common.Div)(
  ({ isMobile }) => css`
    ${customProps};
    flex: initial;
    width: ${isMobile ? '100%' : '215px'};
  `
)

export const PageNumber = styled(Common.Div)<{ isActive?: boolean }>(
  ({ isActive, theme: { colors } }) => css`
    ${customProps};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.textSecondary};

    ${isActive &&
    css`
      background: ${colors.primary};
      border-radius: 50%;
      color: white;
    `}
  `
)

export const InfoBorderRectangle = styled(Common.Div)(
  ({ theme: { colors, params } }) => css`
    align-items: center;
    border-radius: ${params.buttonBorderRadius};
    border: 1px solid ${colors.border};
    display: flex;
    flex-direction: row;
    height: 48px;
    justify-content: center;
    width: calc(100% - 96px);
  `
)

export const VerticalBorderSeparator = styled(Common.Div)(
  ({ theme: { colors } }) => css`
    border-right: 1px solid ${colors.border};
    height: 33px;
    width: 0;
  `
)

export const PaginationContainer = styled(Common.Div)(
  () => css`
    margin-top: auto;
    width: 100%;
  `
)
