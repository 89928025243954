import { yupResolver } from '@hookform/resolvers/yup'
import iconQr from 'assets/icons/iconQr.svg'
import * as Common from 'components/_common/Common'
import { KioskViewContainer, transparentCss } from 'components/_common/Common'
import NextButton from 'components/_common/NextButton'
import {
  notify,
  ToastTypes,
} from 'components/_common/ToastNotification/ToastNotification'
import * as Typography from 'components/_common/TypographyKiosk'
import InputText from 'components/_inputs/InputText'
import {
  ROUTE_CHECKIN_CONFIRM_EMAIL,
  ROUTE_CHECKIN_FIND_RESERVATION,
  ROUTE_CHECKOUT_CONFIRM_EMAIL,
  ROUTE_CHECKOUT_FIND_RESERVATION,
} from 'constants/routes'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import { selectIsKiosk } from 'redux/slices/app'
import {
  selectReservationDetails,
  selectReservationError,
  selectReservationStatus,
} from 'redux/slices/reservation/reservationSelectors'
import { fetchReservationDetails } from 'redux/slices/reservation/reservationSlice'
import styled, { css, useTheme } from 'styled-components'
import { IFindReservationForm } from 'types/room'
import { searchSchema } from 'utils/validation/search'

export const FormContainer = styled(Common.Div)<{
  isInputOpened?: boolean
}>(
  ({ theme: { colors, params } }) => css`
    padding: 38px 105px;
    height: 202px;
    width: 920px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${colors.border};
    gap: 42px;
    border-radius: ${params.buttonBorderRadius};
    ${transparentCss};
  `
)

const ViewCheckInOutReservationNumber = () => {
  const { t } = useTranslation()
  const searchForm = useForm<IFindReservationForm>({
    defaultValues: {
      resNumber: '',
    },
    resolver: yupResolver(searchSchema(t)),
  })

  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isKiosk = useSelector(selectIsKiosk)
  const { pathname } = useLocation()
  const reservationStatus = useSelector(selectReservationStatus)
  const reservationDetails = useSelector(selectReservationDetails)
  const reservationError = useSelector(selectReservationError)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [keyboardVisible, setKeyboardVisible] = useState(false)
  const [resNumber, setResNumber] = useState(
    searchForm.watch('resNumber') || ''
  )

  useEffect(() => {
    if (!keyboardVisible) {
      setResNumber(searchForm.getValues('resNumber') || '')
    }
  }, [keyboardVisible, searchForm])

  const handleSearch: SubmitHandler<IFindReservationForm> = (
    data: IFindReservationForm
  ) => {
    dispatch(fetchReservationDetails(data.resNumber))
  }

  const updateInputValue = (input: string) => {
    setResNumber(input)
    searchForm.setValue('resNumber', input)
  }

  const handleKeyPress = (key: string) => {
    if (key === '{bksp}') {
      setResNumber(prev => prev.slice(0, -1))
      searchForm.setValue('resNumber', resNumber.slice(0, -1))
    } else {
      setResNumber(prev => prev + key)
      searchForm.setValue('resNumber', resNumber + key)
    }
  }

  useEffect(() => {
    if (reservationStatus === 'succeeded') {
      if (pathname === ROUTE_CHECKIN_FIND_RESERVATION) {
        navigate(ROUTE_CHECKIN_CONFIRM_EMAIL)
      } else if (pathname === ROUTE_CHECKOUT_FIND_RESERVATION) {
        navigate(ROUTE_CHECKOUT_CONFIRM_EMAIL)
      }
    } else if (reservationStatus === 'failed') {
      notify(reservationError)[ToastTypes.ERROR]()
    }
  }, [reservationStatus, reservationDetails, navigate, pathname])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setKeyboardVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <KioskViewContainer bgUrl={theme.assets.searchBackground}>
      <Typography.H1
        mt={6}
        mb={10}
        align="center"
        tertiary
      >
        {t('findReservation.header')}
        <br />
        {t('findReservation.subHeader')}
      </Typography.H1>

      <Common.Div
        flex="row"
        justifyContent="center"
        w="100%"
      >
        <FormContainer>
          <Common.Div
            flex="column"
            gap="42px"
          >
            <form onSubmit={searchForm.handleSubmit(handleSearch)}>
              <Common.Div
                position="relative"
                flex="row"
                gap="16px"
                ref={wrapperRef}
              >
                <InputText
                  form={searchForm}
                  name="resNumber"
                  onClick={() => setKeyboardVisible(true)}
                  onFocus={() => setKeyboardVisible(true)}
                  placeholder={t('findReservation.numberPlaceholder')}
                  secondary
                  type="text"
                  w="360px"
                />
                {keyboardVisible && isKiosk && (
                  <Common.Div
                    position="absolute"
                    top={65}
                    w="360px"
                  >
                    <Keyboard
                      baseClass="keyboardResNumber"
                      onChange={(input: string) => updateInputValue(input)}
                      onKeyPress={handleKeyPress}
                      inputName="resNumber"
                      layout={{
                        default: ['1 2 3', '4 5 6', '7 8 9', '* 0 #', '{bksp}'],
                      }}
                      display={{ '{bksp}': '⌫' }}
                    />
                  </Common.Div>
                )}
                <NextButton
                  type="submit"
                  disabled={reservationStatus === 'loading'}
                />
              </Common.Div>
            </form>
            {isKiosk && (
              <Common.Div
                flex="row"
                gap="36px"
              >
                <Common.SvgIcon
                  src={iconQr}
                  w="40px"
                  h="40px"
                />
                <Typography.Body secondary>
                  <Trans i18nKey="findReservation.qrDesc" />
                </Typography.Body>
              </Common.Div>
            )}
          </Common.Div>
        </FormContainer>
      </Common.Div>
    </KioskViewContainer>
  )
}

export default ViewCheckInOutReservationNumber
