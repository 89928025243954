import React from 'react'
import { css, DefaultTheme } from 'styled-components'
import { SPACING_PX } from 'theme'

export type TButtonClickHandler = (
  event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
) => void

export interface ISpacingProps {
  // mobile
  isMobile?: boolean
  // paddings
  p?: number
  pb?: number
  pl?: number
  px?: number
  py?: number
  pr?: number
  pt?: number

  // margins
  m?: number
  mt?: number
  mr?: number
  mb?: number
  ml?: number
  mx?: number
  my?: number
}

export interface ITypographyProps extends ISpacingProps {
  align?: 'center' | 'left' | 'right'
  bold?: boolean
  decoration?: 'underline'
  fontStyle?: 'italic'
  h?: string
  maxW?: string
  onClick?: TButtonClickHandler
  primary?: boolean
  regular?: boolean
  s?: number
  secondary?: boolean
  semiBold?: boolean
  tertiary?: boolean
  textWrap?: 'nowrap' | 'wrap'
  theme: DefaultTheme
  upperCase?: boolean
  lineHeight?: number
}

export const spacingProps = (p: ISpacingProps) => css`
  ${p.m && `margin: ${p.m * SPACING_PX}px`};
  ${p.mt && `margin-top: ${p.mt * SPACING_PX}px`};
  ${p.mr && `margin-right: ${p.mr * SPACING_PX}px`};
  ${p.mb && `margin-bottom: ${p.mb * SPACING_PX}px`};
  ${p.ml && `margin-left: ${p.ml * SPACING_PX}px`};
  ${p.mx &&
  `padding-left: ${p.mx * SPACING_PX}px; padding-right: ${
    p.mx * SPACING_PX
  }px`};
  ${p.my &&
  `padding-top: ${p.my * SPACING_PX}px; padding-bottom: ${
    p.my * SPACING_PX
  }px`};

  ${p.pt && `padding-top: ${p.pt * SPACING_PX}px`};
  ${p.pr && `padding-right: ${p.pr * SPACING_PX}px`};
  ${p.pb && `padding-bottom: ${p.pb * SPACING_PX}px`};
  ${p.pl && `padding-left: ${p.pl * SPACING_PX}px`};
  ${p.p && `padding: ${p.p * SPACING_PX}px`};
  ${p.px &&
  `padding-left: ${p.px * SPACING_PX}px; padding-right: ${
    p.px * SPACING_PX
  }px`};
  ${p.py &&
  `padding-top: ${p.py * SPACING_PX}px; padding-bottom: ${
    p.py * SPACING_PX
  }px`};
`

export interface ICustomStyledProps extends ISpacingProps {
  flex?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  flexGrow?: number
  overflow?: 'scroll' | 'hidden'
  flexDirection?: 'row-reverse' | 'column-reverse' | 'row'
  flexSpan?: number
  flexWrap?: 'wrap' | 'none'
  fullyCentered?: boolean
  justifyContent?:
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'left'
    | 'right'
    | 'space-between'
  alignItems?:
    | 'baseline'
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'space-between'
  w?: string
  h?: string
  position?: 'relative' | 'absolute' | 'initial' | 'fixed'
  zIndex?: number
  gap?: string
  onClick?: TButtonClickHandler
  hidden?: boolean
  maxW?: string
  maxH?: string
  minH?: string
  bottom?: number
  top?: number
  left?: number
  right?: number
}

export const customProps = (p: ICustomStyledProps) => css`
  ${spacingProps};

  ${p.w && `width: ${p.w}`};
  ${p.maxW && `max-width: ${p.maxW}`};
  ${p.maxH && `max-height: ${p.maxH}`};
  ${p.h && `height: ${p.h}`};

  ${p.flex && `display: flex; flex-direction: ${p.flex};`};
  ${p.flexWrap && `flex-wrap: ${p.flexWrap};`};
  ${p.flexGrow && `flex-grow: ${p.flexGrow};`};
  ${p.flexSpan && `flex: ${p.flexSpan};`};
  ${p.fullyCentered &&
  `display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;`};
  ${p.justifyContent &&
  `
    justify-content: ${p.justifyContent};
    -webkit-justify-content: ${p.justifyContent};
    `};
  ${p.flexDirection && `flex-direction: ${p.flexDirection};`};
  ${p.gap && `gap: ${p.gap};`};
  ${p.alignItems &&
  `
    align-items: ${p.alignItems};
    -webkit-align-items: ${p.alignItems};
    `};
  ${p.position && `position: ${p.position};`};
  ${p.zIndex && `z-index: ${p.zIndex};`};
  ${p.overflow && `overflow: ${p.overflow};`};
  ${p.onClick && 'cursor: pointer'};
  ${p.hidden && 'display: none;'};
  ${p.minH && `min-height: ${p.minH};`};
  ${p.bottom && `bottom: ${p.bottom}px;`};
  ${p.top && `top: ${p.top}px;`};
  ${p.right && `right: ${p.right}px;`};
  ${p.left && `left: ${p.left}px;`};
`
