import stepPrintCard from 'assets/graphics/stepCardPrinting.svg'
import stepGraphics from 'assets/graphics/stepReservationDetails.svg'
import noBrandStepGraphics from 'assets/graphics/noBrandStepReservationDetails.svg'
import noBrandStepPrintCard from 'assets/graphics/noBrandStepCardPrinting.svg'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import {
  RESERVATION_STEP_DETAILS,
  RESERVATION_STEP_DETAILS_INFO,
  RESERVATION_STEP_PAYMENT,
  RESERVATION_STEP_PRINT_CARDS,
} from 'constants/app'
import { useAppSelector } from 'hooks/useRedux'
import { useTranslation } from 'react-i18next'
import { selectFitCustomResolution, selectIsKiosk } from 'redux/slices/app'
import styled, { css } from 'styled-components'
import { TReservationStepType, TVariant } from 'types'

const mapStepGraphics = (
  step: TReservationStepType,
  variantTheme: TVariant
) => {
  if (variantTheme === 'NO_BRAND') {
    switch (step) {
      case RESERVATION_STEP_DETAILS:
        return noBrandStepGraphics
      case RESERVATION_STEP_PAYMENT:
        return noBrandStepGraphics
      case RESERVATION_STEP_PRINT_CARDS:
        return noBrandStepPrintCard
      default:
        return noBrandStepGraphics
    }
  }

  switch (step) {
    case RESERVATION_STEP_DETAILS:
      return stepGraphics
    case RESERVATION_STEP_PAYMENT:
      return stepGraphics
    case RESERVATION_STEP_PRINT_CARDS:
      return stepPrintCard
    default:
      return stepGraphics
  }
}

export const StyledStepGraphics = styled(Common.SvgIcon)(
  () => css`
    height: 63px;
    max-height: 63px;
    max-width: 678px;
    width: 100%;
  `
)

export const StepTextWrapper = styled(Common.Div)(
  () => css`
    width: 100%;
    max-width: 678px;
  `
)

export const StepText = styled(Typography.Body2)<{ isActive?: boolean }>(
  ({ theme: { colors }, isActive }) => css`
    color: ${isActive ? colors.primary : colors.textSecondary};
  `
)
export const StepBar = styled(Common.Div)<{ isActive?: boolean }>(
  ({ theme: { colors, params }, isActive }) => css`
    background: ${isActive ? colors.primary : colors.border};
    border-radius: ${params.buttonBorderRadius};
    height: 5px;
    width: 54px;
  `
)

const ReservationSteps = ({
  step,
  withStepBars = true,
}: {
  step: TReservationStepType
  withStepBars?: boolean
}) => {
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 600)
  )
  const isKiosk = useAppSelector(selectIsKiosk)
  const { t } = useTranslation()
  const variantTheme = process.env.REACT_APP_VARIANT as TVariant

  return (
    <>
      <Common.Div
        flex="row"
        justifyContent="center"
        alignItems="center"
      >
        <StyledStepGraphics
          src={mapStepGraphics(step, variantTheme)}
          isMobile={isMobile}
        />
      </Common.Div>
      <StepTextWrapper
        flex="row"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        pr={variantTheme === 'NO_BRAND' ? 0 : 3}
        pl={variantTheme === 'NO_BRAND' ? 0 : 1}
      >
        <StepText
          align="center"
          isActive={
            step === RESERVATION_STEP_DETAILS ||
            step === RESERVATION_STEP_DETAILS_INFO ||
            step === RESERVATION_STEP_PRINT_CARDS
          }
        >
          {t('reservationDetails.findReservation')}
        </StepText>
        <StepText
          align="center"
          isActive={
            step === RESERVATION_STEP_DETAILS ||
            step === RESERVATION_STEP_DETAILS_INFO ||
            step === RESERVATION_STEP_PRINT_CARDS
          }
        >
          {t('reservationDetails.reservationDetails')}
        </StepText>
        <StepText
          isActive={step === RESERVATION_STEP_PRINT_CARDS}
          align="center"
        >
          {t('reservationDetails.cardPrinting')}
        </StepText>
      </StepTextWrapper>
      {withStepBars && (
        <Common.Div
          flex="row"
          gap="8px"
          justifyContent="center"
          mb={isKiosk ? 0 : 4}
        >
          <StepBar isActive={step === RESERVATION_STEP_DETAILS} />
          {isKiosk && (
            <StepBar isActive={step === RESERVATION_STEP_DETAILS_INFO} />
          )}
          <StepBar isActive={step === RESERVATION_STEP_PAYMENT} />
          <StepBar isActive={step === RESERVATION_STEP_PRINT_CARDS} />
        </Common.Div>
      )}
    </>
  )
}

export default ReservationSteps
