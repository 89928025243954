import Button from 'components/_common/Button'
import * as Common from 'components/_common/Common'
import * as Typography from 'components/_common/Typography'
import {
  FacilitiesDescription,
  NightsAdultsText,
  PriceText,
  RoomNameDescription,
  RoomThumbnails,
  TaxChargeText,
} from 'components/_views/FindRoom/RoomRow.styled'
import { ROUTE_MAKE_RESERVATION_DETAILS } from 'constants/routes'
import { useAppSelector } from 'hooks/useRedux'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectFitCustomResolution, selectIsKiosk } from 'redux/slices/app'
import { selectRoom } from 'redux/slices/room/roomActions'
import { IRoom, ISearchParams } from 'types/room'
import { PriceContainer } from 'views/desktopMobile/makeReservation/ViewMakeReservationRoomsFound.styled'

interface IRoomRowProps {
  setIsGalleryOpen: Dispatch<SetStateAction<boolean>>
  data: IRoom
  searchData: ISearchParams
}

export const RoomRow = ({
  setIsGalleryOpen,
  data,
  searchData,
}: IRoomRowProps) => {
  const dispatch = useDispatch()
  const isMobile = useAppSelector(state =>
    selectFitCustomResolution(state, 926)
  )
  const isKiosk = useAppSelector(selectIsKiosk)

  const {
    adults,
    stayDate: { nights },
  } = searchData

  const mobileVariant = (
    <Common.Div flex="column">
      <RoomNameDescription
        isKiosk={false}
        name={data.name}
        description={data.description}
      />
      <Common.Div
        position="relative"
        mb={2}
      >
        <RoomThumbnails
          isMobile={isMobile}
          setIsGalleryOpen={setIsGalleryOpen}
          photo={data.photo}
        />
      </Common.Div>
      <FacilitiesDescription
        amenities={data?.amenities?.data}
        facilities={data?.facilities?.data}
      />
    </Common.Div>
  )

  const websiteVariant = (
    <Common.Div flex="row">
      <Common.Div
        position="relative"
        maxH={isKiosk ? '125px' : 'auto'}
      >
        <RoomThumbnails
          isMobile={isMobile}
          setIsGalleryOpen={setIsGalleryOpen}
          photo={data.photo}
        />
      </Common.Div>
      <Common.Div flex="column">
        <RoomNameDescription
          isKiosk={isKiosk}
          name={data.name}
          description={data.description}
        />
        <FacilitiesDescription
          amenities={data?.amenities?.data}
          facilities={data?.facilities?.data}
        />
      </Common.Div>
    </Common.Div>
  )

  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleSelectRoom = () => {
    dispatch(selectRoom(data))
    navigate(ROUTE_MAKE_RESERVATION_DETAILS)
  }

  return (
    <Common.Div
      flex={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
    >
      {isMobile ? mobileVariant : websiteVariant}
      <PriceContainer
        flex="column"
        alignItems={isMobile ? 'baseline' : 'flex-end'}
        ml={isMobile ? 0 : 4}
        isMobile={isMobile}
      >
        <NightsAdultsText primary>{`${nights} ${t(
          'roomsFound.night'
        )}, ${adults} ${t('roomsFound.adult')}`}</NightsAdultsText>
        <Common.Div
          flex="row"
          alignItems="center"
        >
          <Typography.DiscountText
            mr={2}
            primary
            semiBold
          >
            {data.price}zl
          </Typography.DiscountText>
          <PriceText
            mb={isKiosk ? 0 : 1}
            primary
            semiBold
          >
            {data.price}zl
          </PriceText>
        </Common.Div>
        <TaxChargeText
          secondary
          mb={isKiosk ? 1 : 2}
        >
          {t('roomsFound.taxInclude')}
        </TaxChargeText>
        <Button
          isMobile={isMobile}
          mb={isKiosk ? 0 : 4}
          onClick={handleSelectRoom}
        >
          {t('roomsFound.reserve')}
        </Button>
      </PriceContainer>
    </Common.Div>
  )
}
